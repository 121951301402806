<template>
  <div class="dataentry">
    <VueLoading :active.sync="showLoadingIndicator" :can-cancel="true"></VueLoading>

    <div v-if="currentSportsType" class="dataentry__avatar">
        <div class="dataentry__avatar__mask">
            <img src="@/assets/profile_avatar_mask.svg" alt="" />
        </div>
        <img :src="'https://app.sportlerplus.com/scaled/800/' + currentSportsType.image_url" />
    </div>

    <div v-else-if="currentActivity && currentActivity.activity" class="card">
        <div class="card__title">{{ currentActivity.activity.title }}</div>
        <img class="card__image" :src="'https://app.sportlerplus.com/scaled/800/' + currentActivity.activity.image_url" />
    </div>

    <h1 v-if="currentSportsType">Bist Du fertig?</h1>
    <p v-if="currentSportsType">Du kannst deine Daten manuell eintragen oder aus GoogleFit oder AppleHealth importieren.</p>

    <!-- TODO @Marco: Add class ".expanded" if user has clicked on the cta below -->
    <p v-else-if="currentActivity && currentActivity.activity" v-html="currentActivity.activity.description" class="expanded"></p>

    <!-- TODO @Marco: Should be only visible if line clamp is active -->
    <div v-if="currentActivity && currentActivity.activity" class="dataentry__moretext">
        Alles anzeigen
    </div>

    <!-- Button: Fitnessdaten importieren -->
    <v-btn @click="openTrackedWorkoutsModal" class="button button--outline button--small button--icon">
        <img src="@/assets/ic_16_share.svg" alt="">
        Fitnessdaten importieren
    </v-btn>

    <!-- Divider -->
    <div class="divider">
        oder
    </div>

    <!-- Inputfelder -->
    <div class="stepper__container">

        <!-- Import Hint -->
        <div class="stepper__hint" v-if="trackedWorkoutFrom > 0">
            <!-- TODO @Marco: Hier muss abhängig vom Datenlieferanten ein anderer Name stehen -->
            Von {{ getNameOfImportSource }} importiert
        </div>

        <!-- Zeit -->
        <div class="stepper">
            <div class="stepper__icon stepper__icon__down">
                <img v-on:click="changeDuration(-60)" src="@/assets/ic_32_stepper_down.svg" alt=""/>
            </div>
            <div class="stepper__value">
                <input v-if="this.trackedWorkoutFrom < 1" @click="selectText($event.target)" @blur="duration = parseTime($event.target.value) || 0" class="stepper__value" :value="
                displayTime + ' Min:Sek'" :class="duration > 0 ? '' : 'empty'">
                <input v-else @click="openChangeTrackedDataAlertModal" :value="displayTime + ' Min:Sek'" :class="duration > 0 ? '' : 'empty'">
            </div>
            <div class="stepper__icon stepper__icon__up">
                <img v-on:click="changeDuration(60)" src="@/assets/ic_32_stepper_up.svg" alt=""/>
            </div>
        </div>

        <!-- Distanz -->
        <div class="stepper">
            <div class="stepper__icon stepper__icon__down">
                <img v-on:click="changeDistance(-1)" src="@/assets/ic_32_stepper_down.svg" alt=""/>
            </div>
            <div class="stepper__value">
                <input v-if="this.trackedWorkoutFrom < 1" @click="selectText($event.target)" @blur="distance = formatData($event.target.value) || 0" class="stepper__value" :value="
                distance + ' km'" :class="distance > 0 ? '' : 'empty'">
                <input v-else @click="openChangeTrackedDataAlertModal" :value="distance + ' km'" :class="distance > 0 ? '' : 'empty'">
            </div>
            <div class="stepper__icon stepper__icon__up">
                <img v-on:click="changeDistance(1)" src="@/assets/ic_32_stepper_up.svg" alt=""/>
            </div>
        </div>

        <!-- Kalorien -->
        <div class="stepper">
            <div class="stepper__icon stepper__icon__down">
                <img v-on:click="changeEnergy(-1)" src="@/assets/ic_32_stepper_down.svg" alt=""/>
            </div>
            <div class="stepper__value">
                <input v-if="this.trackedWorkoutFrom < 1" @click="selectText($event.target)" @blur="energy = formatData($event.target.value) || 0" class="stepper__value" :value="
                displayEnergy + ' kcal'" :class="energy > 0 ? '' : 'empty'">
                <input v-else @click="openChangeTrackedDataAlertModal" :value="displayEnergy + ' kcal'" :class="energy > 0 ? '' : 'empty'">
            </div>
            <div class="stepper__icon stepper__icon__up">
                <img v-on:click="changeEnergy(1)" src="@/assets/ic_32_stepper_up.svg" alt=""/>
            </div>
        </div>

        <!-- Datum -->
        <div class="stepper">
            <div class="stepper__icon stepper__icon__down">
                <img v-on:click="previousDay()" src="@/assets/ic_32_stepper_down.svg" alt=""/>
            </div>
            <div class="stepper__value">
                <input v-if="this.trackedWorkoutFrom < 1" type="date" placeholder="tt.mm.jjjj" v-model="activityDate" class="has-value" onchange="this.className=(this.value!=''?'has-value':'')">
                <input v-else @click="openChangeTrackedDataAlertModal" type="date" placeholder="tt.mm.jjjj" v-model="activityDate" class="has-value">
            </div>
            <div class="stepper__icon stepper__icon__up">
                <img v-on:click="nextDay()" src="@/assets/ic_32_stepper_up.svg" alt=""/>
            </div>
        </div>

        <ChangeTrackedDataAlertModal :title="'Vorsicht!'" :message="'Möchtest du wirklich die importierten Daten ändern?'" :agreeText="'Ja, Daten ändern'" :disagreeText="'Daten nicht ändern'" @acceptChanges=allowChanges @closeChangeTrackedDataAlertModal=closeChangeTrackedDataAlertModal>></ChangeTrackedDataAlertModal>
        <TrackedWorkoutsModal v-if="activityDate" :realStartDate="activityDate" @importWorkout="importWorkout" @closeTrackedWorkoutsModal=closeTrackedWorkoutsModal></TrackedWorkoutsModal>

    </div>
    
    <v-btn @click="completeFitnessPlanEvent" class="button button--primary">Training abschließen</v-btn>

  </div>
</template>
<script>

import moment from "moment";
import VueLoading from 'vue-loading-overlay/dist/vue-loading';
// Import stylesheet
import 'vue-loading-overlay/src/css/index.css';
import TrackedWorkoutsModal from "@/components/TrackedWorkoutsModal.vue";
import ChangeTrackedDataAlertModal from "@/components/ChangeTrackedDataAlertModal.vue";
import {analytics} from '@/firebase/index.js';
export default {
    name: 'FitnessPlanEditCustomActivity',
    components: {ChangeTrackedDataAlertModal, TrackedWorkoutsModal, VueLoading },
    data() {
        return {
            sportsTypes: [],
            currentSportsType: null,
            sportsTypeId: null,
            distance: 0,
            energy: 0,
            duration: 0,
            activityTime: "",
            activityDate: "",
            realStartDate: null,
            fitnessPlan: [],
            currentActivity: null,
            showLoadingIndicator: false,
            trackingData: null,
            // tracked from Apple HealthKit = 1, from GoogleFit = 2, not tracked = -1, manual changed tracking data = 0
            trackedWorkoutFrom: null,
        }
    },
    computed: {
        displayTime() {
            if (this.duration) {
                let m = Math.floor(this.duration / 60);
                let s = Math.floor(this.duration % 60);
                if(s > 0 && s < 10) {
                    return m + ":0" + s;
                }
                else if (s > 10) {
                    return m + ":" + s;
                }
                return m + ":00";
            }
            return "0:00";
        },
        displayEnergy() {
            if (this.energy) {
                return Math.round(this.energy);
            }
            return 0;
        },
        getNameOfImportSource() {
            if (this.trackedWorkoutFrom === 1) {
                return "Apple HealthKit"
            }
            else {
                return "GoogleFit"
            }
        },
    },
    methods: {
        init() {
            this.showLoadingIndicator = true;
            this.sportsTypeId = parseInt(this.$route.query.sportsType_id);
            this.fetchSportsTypes();
            this.loadFitnessPlan();
        },
        importWorkout(workout) {
            this.duration = workout.duration;
            // Convert meters to kilometers and get 2 decimal places
            this.distance = Math.round(workout.distance.value / 10) / 100;
            this.energy = workout.energy.value;
            this.activityTime = workout.from_utc.split(" ")[1];
            this.activityDate = workout.from_utc.split(" ")[0];
            this.realStartDate = moment(workout.from_utc.split(" ")[0] + ' ' + workout.from_utc.split(" ")[1], 'yyyy_MM-DD HH:mm').format('yyyy_MM-DD HH:mm').toString();
            this.trackedWorkoutFrom = workout.import_source;
        },
        openTrackedWorkoutsModal() {
            this.$modal.show("trackedWorkouts");
            analytics.logEvent('try_import_of_tracking_data');
        },
        closeTrackedWorkoutsModal() {
            this.$modal.hide("trackedWorkouts");
        },
        myParseFloat(f) {
            return parseFloat(f.replace(/,/, "."));
        },
        formatData(f) {
            return Math.round(this.myParseFloat(f) * 100) / 100;
        },
        parseTime(t) {
            let tc = t.split(":");
            if (tc.length == 2) {
                let minutes = parseInt(tc[0]);
                let seconds = parseInt(tc[1]);
                return minutes * 60 + seconds;
            }
            if (tc.length == 3) {
                let hours = parseInt(tc[0]);
                let minutes = parseInt(tc[1]);
                let seconds = parseInt(tc[2]);
                return hours * 3600 + minutes * 60 + seconds;
            }
            else {
                return parseInt(tc[0]) * 60;
            }
        },
        fetchSportsTypes() {
            this.$store.dispatch('fetchSportsTypes').then( () => {
                this.sportsTypes = this.$store.getters.getSportsTypes;
                this.currentSportsType = this.sportsTypes.find(st => st.id == this.sportsTypeId);
            });
        },
        changeDistance(val) {
            if (this.trackedWorkoutFrom < 1) {
                this.distance = Math.max(0, this.distance + val);
            }
            else {
                this.openChangeTrackedDataAlertModal();
            }
        },
        changeEnergy(val) {
            if (this.trackedWorkoutFrom < 1) {
                this.energy = Math.max(0, this.energy + val);
            }
            else {
                this.openChangeTrackedDataAlertModal();
            }
        },
        changeDuration(val) {
            if (this.trackedWorkoutFrom < 1) {
                this.duration = Math.max(0, this.duration + val);
            }
            else {
                this.openChangeTrackedDataAlertModal();
            }
        },
        previousDay() {
            if (this.trackedWorkoutFrom < 1) {
                this.activityDate = moment(this.activityDate).subtract(1, 'days').format('yyyy-MM-DD').toString();
            }
            else {
                this.openChangeTrackedDataAlertModal();
            }
        },
        nextDay() {
            if (this.trackedWorkoutFrom < 1) {
                this.activityDate = moment(this.activityDate).add(1, 'days').format('yyyy-MM-DD').toString();
            }
            else {
                this.openChangeTrackedDataAlertModal();
            }
        },
        openChangeTrackedDataAlertModal() {
            this.$modal.show("changeTrackedDataAlert");
        },
        closeChangeTrackedDataAlertModal() {
            this.$modal.hide("changeTrackedDataAlert");
        },
        allowChanges() {
            this.trackedWorkoutFrom = 0;
            this.$modal.hide("changeTrackedDataAlert");
        },
        async completeFitnessPlanEvent() {
            if (this.currentActivity) {
                this.showLoadingIndicator = true;
                if (!this.currentActivity.trackedWorkoutFrom || this.currentActivity.trackedWorkoutFrom < 1) {
                    this.currentActivity.trackedWorkoutFrom = this.trackedWorkoutFrom;
                    this.currentActivity._realDuration = this.currentActivity.realDuration || "0";
                    this.currentActivity._energyExpended = this.currentActivity.energyExpended || "0";
                    this.currentActivity._distance = this.currentActivity.distance || "0";
                    this.currentActivity._activityState = this.currentActivity.activityState;
                    if (!this.currentActivity._realStartDate) {
                        this.currentActivity._realStartDateOld = moment(this.currentActivity.activityDate + ' ' + this.currentActivity.activityTime, 'yyyy-MM-DD HH:mm').format('yyyy-MM-DD HH:mm').toString();
                    }
                }
                this.currentActivity.activityState = "finished";
                this.currentActivity.distance = this.distance;
                this.currentActivity.realDuration = this.duration / 60;
                this.currentActivity.energyExpended = this.energy;
                this.currentActivity._realStartDate = moment(this.activityDate + ' ' + this.activityTime, 'yyyy-MM-DD HH:mm').format('yyyy-MM-DD HH:mm').toString();
                await this.updateFitnessPlan();
                this.showLoadingIndicator = false;
                await this.$router.push("/fitnessplan?date=" + this.activityDate);
                //this.$router.go(-1);
            }
        },
        selectText(elem) {
            elem.select();
        },
        loadFitnessPlan() {
            const eventInfo = {
                guid: this.$route.query.guid,
                activityDate: this.$route.query.activityDate,
                activityTime: this.$route.query.activityTime,
            };
            this.$store.dispatch('fetchFitnessPlan').then( (res) => {
                if (res.length != 0) {
                    this.fitnessPlan = res[0];
                    let existingStartedActivity = null;
                    if (this.fitnessPlan.startedActivities) {
                        existingStartedActivity = this.fitnessPlan.startedActivities.find(el => el.guid == eventInfo.guid && el.activityDate == eventInfo.activityDate && el.activityTime == eventInfo.activityTime);
                    }
                    else {
                        this.fitnessPlan.startedActivities = [];
                    }
                    this.currentActivity = existingStartedActivity;
                    if (existingStartedActivity) {
                        this.distance = existingStartedActivity.distance || 0;
                        this.energy = existingStartedActivity.energyExpended || 0;
                        this.duration = Math.round((existingStartedActivity.realDuration || 0) * 60);
                        if(existingStartedActivity.trackedWorkoutFrom) {
                            this.trackedWorkoutFrom = existingStartedActivity.trackedWorkoutFrom;
                        }
                        if (existingStartedActivity._realStartDate) {
                            this.activityDate = moment(existingStartedActivity._realStartDate, 'yyyy-MM-DD HH:mm').format('yyyy-MM-DD').toString();
                            this.activityTime = moment(existingStartedActivity._realStartDate, 'yyyy-MM-DD HH:mm').format('HH:mm').toString();
                            this.realStartDate = moment(existingStartedActivity._realStartDate, 'yyyy-MM-DD HH:mm').format('yyyy-MM-DD HH:mm').toString();
                        } else {
                            this.activityDate = moment(existingStartedActivity.activityDate, 'yyyy-MM-DD').format('yyyy-MM-DD').toString();
                            this.activityTime = moment(existingStartedActivity.activityTime, 'HH:mm').format('HH:mm').toString();
                            this.realStartDate = moment(existingStartedActivity.activityDate + ' ' + existingStartedActivity.activityTime, 'yyyy-MM-DD HH:mm').format('yyyy-MM-DD HH:mm').toString();
                        }
                    }
                }
                this.showLoadingIndicator = false;
            });
        },
        async updateFitnessPlan() {
            await this.$store.dispatch('saveFitnessPlan', this.fitnessPlan);
        },
    },
    mounted() {
        this.init();
    },
}
</script>

<style lang="scss">
 .dataentry {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 480px;
    margin: 0px auto;

    &__header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 10px 0 40px;
    }

    &__avatar {
      position: relative;
      width: 104px;
      height: 118px;
      margin: 0px auto 19px;
      background: rgba($colorPrimary, .24);
      transform: scale(1.5);
      margin-top: 24px;
      margin-bottom: 50px;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 24px;
        height: 4px;
        transform: translate(-50%,-50%);
        background: $colorPrimary;
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 4px;
        height: 24px;
        transform: translate(-50%,-50%);
        background: $colorPrimary;
      }


      &__mask {
        position: absolute;
        z-index: 2;
        display: flex;
        pointer-events: none;
      }
      
      > img {
        position: relative;
        z-index: 1;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        margin: 1px;
        object-fit: cover;
      }
    }

    h1, p {
      color: $colorBlack;
      text-align: center;
      max-width: 480px;
      margin-left: auto;
      margin-right: auto;
    }

    h1 {
        margin-bottom: 7px;
    }

    p {
        margin-bottom: 37px;

        &:not(.expanded) {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;  
            overflow: hidden;
            max-height: 72px;
        }

        &.expanded {
            max-height: 100%;

            > p {
                max-height: 100%;
                display: inherit;
                -webkit-line-clamp: inherit;
                -webkit-box-orient: intherit;  
                overflow: visible;
                max-height: inherit;
            }

            ~ .dataentry__moretext {
                display: none;
            }
        }
    }

    &__moretext {
        position: relative;
        margin: -36px auto 32px;
        font-size: 14px;
        font-weight: 600;
        color: $colorBlack;
        cursor: pointer;
        padding: 8px 16px 8px 0;

        &:after {
            content: '';
            position: absolute;
            top: calc(50% - 6px);
            right: 0;
            width: 12px;
            height: 12px;
            background: url('../assets/ic_12_down.svg');
        }
    }

    input {
        &.empty {
            opacity: .24;
        }
    }

    .divider {
        margin: 7px 0 22px;
    }
}
</style>

<style lang="scss" scoped>
    .button--outline {
        font-size: 17px;
        box-shadow: 0 0 0 1px rgba($colorBlack, .08), 0 8px 16px 0 rgba($colorBlack, .08);
    }

    .stepper__container {
        background: $colorWhite;
        border-radius: 12px;
        box-shadow: 0 0 0 1px rgba($colorBlack, .04), 0 8px 16px 0 rgba($colorBlack, .08);
        padding: 8px 0;
        margin-bottom: 32px;
        overflow: hidden;
    }

    .stepper__hint {
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba($colorPrimary, .08);
        box-shadow: 0 1px 0 0 $colorPrimary;
        font-size: 14px;
        line-height: 10px;
        font-weight: 700;
        color: $colorPrimary;
        margin-top: -8px;
        padding: 19px 0;
    }

    .stepper {
        padding: 16px;
        margin: 0;
    }

    .stepper__value {
        font-size: 17px;
    }

    input[type="date"] {
        min-height: inherit;
    }
</style>