<template>
    <VModal @closed="emitClose" name="changeTrackedDataAlert" class="notification-modal centered" width="100%" height="auto" :scrollable="true">
        <div class="vm-content">
            <img src="@/assets/notification/ic_notification_warning.svg" alt="">
            <h3>
                {{ title }}
            </h3>
            <p v-html="message">
            </p>
            <v-btn type="button" class="button button--secondary button--small" @click="dismissDialog">{{ disagreeText }}</v-btn>
            <v-btn type="button" class="button button--primary button--small" @click="emitChanges">{{ agreeText }}</v-btn>
        </div>
    </VModal>
</template>

<script>
export default {
    name: 'ChangeTrackedDataAlertModal',
    props: {
        title: {
            type: String,
            default() { return ""; }
        },
        message: {
            type: String,
            default() { return ""; }
        },
        agreeText: {
            type: String,
            default() { return ""; }
        },
        disagreeText: {
            type: String,
            default() { return ""; }
        }
    },
    methods: {
        dismissDialog() {
            this.$modal.hide('changeTrackedDataAlert');
        },
        emitClose() {
            this.$emit('closeChangeTrackedDataAlert');
        },
        emitChanges(){
            this.$emit('acceptChanges')
        }
    }
}
</script>