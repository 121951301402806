<template>

    <!-- TODO @Marco: Wenn der Fehlerdialog "Es wurden keine Fitnessdaten gefunden" angezeigt wird, muss am <VModal> ":shiftY=1" hinzugefügt und die Klassen "class='notification-modal centered'" entfernt werden -->
    <VModal name="trackedWorkouts" width="100%" height="auto" :shiftY="1" :scrollable="true">
        <!-- Fehler-Modal Currently not in use-->
        <!-- Fehler-Modal ("Es wurden keine Fitnessdaten gefunden")-->
        <div v-if="trackingError">
            <div class="vm-header">
                <div class="vm-header-left">
                </div>
                <div class="vm-header-center">
                </div>
                <div class="vm-header-right">
                    <img src="@/assets/ic_nav_close.svg" alt="" @click="closeTrackedWorkoutsModal">
                </div>
            </div>
            <div class="vm-content">
                <img class="tracking-settings-image" src="@/assets/tracking-settings.png" srcset="@/assets/tracking-settings@2x.png 2x" alt="">
                <h3>Es wurden keine Fitnessdaten gefunden</h3>
                <p>Bitte prüfe, ob du die Freigabe zur Nutzung der Fitnessdaten aktiviert hast.</p>
                <ul class="tracking-tutorial">
                    <li>Rufe in den Einstellungen deines Handys <span>„Health“</span> auf.</li>
                    <li>Wähle <span>„Datenzugriff & Geräte“</span> und danach „SportlerPlus“ aus.</li>
                    <li>Aktiviere den Eintrag <span>„Trainings“</span>.</li>
                </ul>
                <v-btn type="button" class="button button--primary button--small" @click="closeTrackedWorkoutsModal">Alles klar</v-btn>
            </div>
        </div>

        <!-- Auswahl-Modal ("Fitnessdaten auswählen") -->
        <div v-else>
            <div class="vm-header">
                <div class="vm-header-left">
                </div>
                <div class="vm-header-center">
                    Fitnessdaten auswählen
                </div>
                <div class="vm-header-right">
                    <img src="@/assets/ic_nav_close.svg" alt="" @click="closeTrackedWorkoutsModal">
                </div>
            </div>
            <div class="vm-content">
                <p>Wähle die Trackingdaten aus, die du für dieses Training aufgezeichnet hast.</p>

                <div class="trackinglist">

                    <!-- Wochenauswahl -->
                    <div class="trackinglist__date">
                        <div class="trackinglist__date__content">
                            <div class="trackinglist__date__button">
                                <img src="@/assets/ic_16_arrow_left.svg" alt="" v-on:click="getWorkoutsOfPreviousWeek()">
                            </div>
                            <div class="trackinglist__date__title">
                                 {{ getStartDate }} - {{ getEndDate }}
                            </div>
                            <!-- TODO @Marco: Ist man in der aktuellen Woche ist (also alle späteren Wochen in der Zukunft liegen), soll der Button mittels der disabled-Klasse ausgeblendet werden -->
                            <div :class="showNextDateArrow ? 'trackinglist__date__button' : 'trackinglist__date__button--disabled'">
                                <img src="@/assets/ic_16_arrow_right.svg" alt="" v-on:click="getWorkoutsOfNextWeek">
                            </div>
                        </div>
                    </div>

                    <!-- TODO @Marco: Der Emptystate gilt je Woche -> wenn in dieser Woche keine Daten vorliegen wird also der Emptystate angezeigt, wenn man aber in die vorherige Woche wechselt, kann es sein, dass dort wieder Daten angezeigt werden -->
                    <!-- Empty State -->
                    <div class="trackinglist__empty" v-if="!trackingData || trackingData.length < 1">
                        <div class="trackinglist__empty__title">
                            Keine Daten vorhanden
                        </div>
                        <div class="trackinglist__empty__subtitle">Für diese Woche liegen aktuell keine Trackingdaten vor. Bitte überprüfe den ausgewählten Zeitraum und ob du die Freigabe zur Nutzung der Fitnessdaten in den Einstellungen deines Handys aktiviert hast.</div>
                    </div>

                    <!-- TODO @Marco: Wenn ein Eintrag ausgewählt wurde, muss er wie hier um die Klasse ".contentItem--selected" erweitert werden -->
                    <!-- Vorschgeschlagener Datensatz -->
<!--                    <div class="contentItem contentItem&#45;&#45;selected">-->
<!--                        <div class="contentItem__badge">-->
<!--                            Vorschlag-->
<!--                        </div>-->
<!--                        <div class="contentItem__icon">-->
<!--                            <img src="@/assets/contentitem/ic_contentitem_tracking.svg" alt="" />-->
<!--                        </div>-->
<!--                        <div class="contentItem__content">-->
<!--                            <div class="contentItem__title">-->
<!--                                Laufen (57:13 Min)-->
<!--                            </div>-->
<!--                            <div class="contentItem__meta">-->
<!--                                Heute um 10:07 Uhr-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->

                    <!-- Beispiel für weitere Datensätze aus der ausgewählten Woche -->
                    <div :class="markedWorkout && markedWorkout.workout_id === workout.workout_id ? 'contentItem contentItem--selected': 'contentItem'" v-else v-for="workout in trackingData" :key="workout.workout_id" @click="markWorkout(workout)" >
                        <div class="contentItem__icon">
                            <img src="@/assets/contentitem/ic_contentitem_tracking.svg" alt="" />
                        </div>
                        <div class="contentItem__content">
                            <div class="contentItem__title">
                                {{ workout.type }} ({{ formatDuration(workout.duration) }} Std:Min)
                            </div>
                            <div class="contentItem__meta">
                                {{ formatDate(workout.from_utc) }} Uhr
                            </div>
                        </div>
                    </div>

                </div>

                <!-- TODO @Marco: Der Button soll so lange deaktiviet/disabled dargestellt werden, bis ein Eintrag ausgewählt wurde -->
                <v-btn :disabled="markedWorkout === null" class="button button--primary" @click="importMarkedWorkout">
                    Bestätigen
                </v-btn>
            </div>
        </div>

    </VModal>

</template>

<script>
import moment from "moment";
import {analytics} from "@/firebase";

export default {
    name: 'TrackedWorkoutsModal',
    data() {
        return {
            selectedWorkoutId: null,
            markedWorkout: null,
            trackingData: null,
            startOfWorkoutWeek: "",
            endOfWorkoutWeek: "",
            // Currently not in use
            trackingError: null,
        }
    },
    props: {
        realStartDate: {
            type: String,
            default() { return ""; }
        },
    },
    mounted() {
        window.vm.trackedWorkoutsModalComponent = this;
        this.init();
    },
    computed: {
        getStartDate() {
            return moment(this.startOfWorkoutWeek).locale('de').format("DD. MMM");
        },
        getEndDate() {
            return moment(this.endOfWorkoutWeek).locale('de').format("DD. MMM YYYY");
        },
        showNextDateArrow() {
            return this.startOfWorkoutWeek < moment().endOf('week').subtract(1, 'weeks');
        },
    },
    methods: {
        init() {
            this.calculateWorkoutWeek();
            this.showTrackedWorkoutData();
        },
        setTrackingData(d) {
            this.trackingData = d;
        },
        showTrackedWorkoutData() {
            let startOfWeek = moment(this.startOfWorkoutWeek).format('yyyy-MM-DD').toString();
            let endOfWeek = moment(this.endOfWorkoutWeek).format('yyyy-MM-DD').toString();
            if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.shareTrackingDataHandler) {
                window.webkit.messageHandlers.shareTrackingDataHandler.postMessage({startOfWorkoutWeek: startOfWeek, endOfWorkoutWeek: endOfWeek});
            }
            if (window.AndroidJSInterface != undefined) {
                window.AndroidJSInterface.getTrackingData(startOfWeek, endOfWeek);
            }
        },
        calculateWorkoutWeek() {
            this.startOfWorkoutWeek = moment(this.realStartDate, 'yyyy-MM-DD').locale('de').startOf('week');
            this.endOfWorkoutWeek = moment(this.realStartDate, 'yyyy-MM-DD').locale('de').endOf('week');
        },
        closeTrackedWorkoutsModal() {
            this.$emit('closeTrackedWorkoutsModal');
        },
        importMarkedWorkout() {
            analytics.logEvent('import_tracking_data');
            this.$emit('importWorkout', this.markedWorkout)
            this.markedWorkout = null;
            this.$emit("closeTrackedWorkoutsModal");
        },
        formatDate(date) {
            return moment(date).locale('de').calendar({
                    sameDay: '[Heute um] HH:mm',
                    lastDay: '[Gestern um] HH:mm',
                    lastWeek: '[letzten] dddd [um] HH:mm',
                    sameElse: 'dddd DD. MMM YYYY [um] HH:mm'
                }
            );
        },
        formatDuration(duration) {
            let hours = Math.floor(duration / 3600);
            let minutes = duration % 3600;
            minutes = this.padToTwoDigits(Math.floor(minutes / 60));
            return hours + ":" + minutes;
        },
        padToTwoDigits(num) {
            return num.toString().padStart(2, "0");
        },
        markWorkout(selectedWorkout) {
            if (this.markedWorkout === null || this.markedWorkout.workout_id !== selectedWorkout.workout_id) {
                this.markedWorkout = selectedWorkout;
            }
            else {
                this.markedWorkout = null;
            }
        },
        getWorkoutsOfPreviousWeek() {
            this.startOfWorkoutWeek = moment(this.startOfWorkoutWeek).subtract(1, 'weeks');
            this.endOfWorkoutWeek = moment(this.endOfWorkoutWeek).subtract(1, 'weeks');
            this.showTrackedWorkoutData();
        },
        getWorkoutsOfNextWeek() {
            if(this.showNextDateArrow) {
                this.startOfWorkoutWeek = moment(this.startOfWorkoutWeek.add(1, 'weeks'));
                this.endOfWorkoutWeek = moment(this.endOfWorkoutWeek.add(1, 'weeks'));
                this.showTrackedWorkoutData();
            }
        },
    },

}

</script>

<style lang="scss">
    .trackinglist {
        margin-bottom: 24px;

        &__date {
            position: relative;
            z-index: 10;
            margin: -16px 0 40px;

            &:before {
                content: '';
                position: absolute;
                z-index: -1;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 26px;
                border-radius: 50%;
                box-shadow: 0 0 16px 0 rgba(0,0,0,0.08);
                transform: translateZ(-1px);
            }

            &__content {
                display: flex;
                align-items: center;
                justify-content: space-between;
                background: $colorWhite;
                box-shadow: 0 1px 0 0 rgba($colorBlack, .04);
                margin: 0 -16px;
                padding: 0 16px;
            }

            &__title {
                font-size: 14px;
                font-weight: 600;
                color: $colorBlack;
                padding: 13px 0;
            }

            &__button {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: -12px;
                padding: 12px;

                &--disabled {
                    opacity: .24;
                }
            }
        }

        &__empty {
            padding: 64px 24px;
            text-align: center;
            background: rgba($colorBlack, .04);
            border-radius: 8px;
            margin-bottom: 16px;

            &__title {
                font-size: 14px;
                font-weight: 600;
                color: rgba($colorBlack, .64);
                margin-bottom: 8px;
            }

            &__subtitle {
                font-size: 14px;
                font-weight: 500;
                color: rgba($colorBlack, .48);
            }
        }
    }
</style>

<style lang="scss" scoped>
    .tracking-settings-image {
        margin: -28px auto -16px !important;
    }
    .tracking-tutorial {
        padding: 0;
        display: flex;
        flex-direction: column;
        list-style-type: none;
        margin: -8px 0 28px;

        li {
            position: relative;
            padding-left: 36px;
            font-size: 14px;
            line-height: 20px;
            font-weight: 600;
            color: rgba($colorBlack, .48);
            text-align: left;
            margin: 6px 0;

            span {
                color: $colorBlack;
            }

            &:before {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 0;
                left: 0;
                width: 23px;
                height: 23px;
                border-radius: 50%;
                background: rgba($colorBlack, .08);
                color: rgba($colorBlack, .64);
                font-size: 11px;
                font-weight: 700;
            }

            &:nth-child(1) {
                &:before {
                    content: '1';
                }
            }

            &:nth-child(2) {
                &:before {
                    content: '2';
                }
            }

            &:nth-child(3) {
                &:before {
                    content: '3';
                }
            }

            &:not(:last-child) {
                &:after {
                    content: '';
                    position: absolute;
                    width: 1px;
                    height: calc(100% - 11px);
                    top: 23px;
                    left: 11px;
                    background: rgba($colorBlack, .08);
                }
            }
        }
    }
</style>